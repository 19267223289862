import { useApolloClient } from '@apollo/client';
import {
  Box, Button,
  Card, CardActions, CardContent, CardHeader, Tooltip, Typography
} from '@mui/material';
import React from 'react';
import {
  DarkNegativeCreateIcon
} from '../icons/icons';

const CommonActionsCard = () => {
  const globalState = useApolloClient();

  return (
    <Box>
      <Card style={{ padding: '10px', marginBottom: '10px' }}>
        <CardHeader title={'What would you like to do today?'}></CardHeader>
        <CardContent>
          <Typography> It's the perfect day to start a new project!</Typography>
        </CardContent>
        <CardActions>
          <Tooltip title="This is still under construction">
            <Button
              variant="contained"
              color="secondary"
              // component="span"
              startIcon={<DarkNegativeCreateIcon fontSize="large" />}
            >
              Build a new map
            </Button>
          </Tooltip>
          <Tooltip title="This is still under construction">
            <Button
              variant="contained"
              color="primary"
              // component="span"
              startIcon={<DarkNegativeCreateIcon fontSize="large" />}
            >
              Check on project status
            </Button>
          </Tooltip>
        </CardActions>
      </Card>
    </Box>
  );
};

export default CommonActionsCard;
