import { Box, Grid } from '@mui/material';
import React from 'react';
import NewsCard from '../news/NewsCard';
import CommonActionsCard from './common-actions-card';

import RobotSummaryCard from './RobotSummaryCard';
import WelcomeAlert from './welcome-alert';

export default function HomeView() {
  return (
    <Box style={{ overflowY: 'auto' }}>
      <WelcomeAlert />
      <Grid container>
        <Grid item xs={6} style={{ padding: '10px' }}>
          <CommonActionsCard></CommonActionsCard>
          <RobotSummaryCard></RobotSummaryCard>
        </Grid>
        <Grid item xs={6} style={{ padding: '10px' }}>
          {/* <MapperSummaryCard></MapperSummaryCard> */}
          <NewsCard maxItems={5}/>
        </Grid>
      </Grid>
    </Box>
  );
}
