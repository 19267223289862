import { Box, Button, CircularProgress, CircularProgressProps, Typography } from '@mui/material';
import React from 'react';

export default function CircularProgressWithLabel(
  props: CircularProgressProps & { label?: string; onClick?: () => void; buttonLabel?: string },
) {
  return (
    <Box style={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress {...props} color="secondary" />
      <Box
        style={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {props.label && (
          <Typography variant="caption" component="div" color="secondary">
            {props.label}
          </Typography>
        )}
        {props.buttonLabel ? (
          <Button
            onClick={() => (props.onClick ? props.onClick() : null)}
            size="small"
            color="secondary"
            variant="outlined"
          >
            {props.buttonLabel}
          </Button>
        ) : null}
      </Box>
    </Box>
  );
}
