import { Alert, AlertTitle, Box, Button, Typography } from '@mui/material';
import React from 'react';

export default function WelcomeAlert() {
  // const globalState = useApolloClient();

  return (
    <Alert>
      <AlertTitle>Welcome to NavAbility!</AlertTitle>
      <Typography style={{ padding: '10px' }}>Let's get started! Here are a few ways to dive in: </Typography>
      <Box>
        <Button href='/get-started/tutorials' style={{ marginLeft: '10px' }} color="secondary" variant="contained">
          Do some tutorials
        </Button>
        <Button href='/get-started/introduction' style={{ marginLeft: '10px' }} color="primary" variant="contained">
          Get started with our online demo!
        </Button>
        <Button href='/get-started/experiment' style={{ marginLeft: '10px' }} color="primary" variant="contained">
          Create example data
        </Button>
      </Box>
    </Alert>
  );
}
