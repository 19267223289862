import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

export function RobotIcon(props: any) {
  return (
    <SvgIcon width="1em" height="1em" viewBox="0 0 33.867 33.867" {...props}>
      <style type="text/css" id="style2">
        {'.st0{fill:#2f1c63}.st1{fill:#9053ec}'}
      </style>
      <g id="g82" transform="translate(-75.497 -62.54) scale(.85141)">
        <path
          className="st0"
          d="M171 204.5h-62.2c-6.6 0-12-5.4-12-12s5.4-12 12-12H171c6.6 0 12 5.4 12 12s-5.4 12-12 12z"
          id="path4"
          style={{
            fill: '#2f1c63',
          }}
        />
        <path
          className="st1"
          d="M184.8 204.5h-62.2c-6.6 0-12-5.4-12-12s5.4-12 12-12h62.2c6.6 0 12 5.4 12 12-.1 6.6-5.4 12-12 12z"
          id="path6"
          style={{
            fill: '#9053ec',
          }}
        />
        <circle
          className="st0"
          cx={122.1}
          cy={192}
          r={2.9}
          id="circle8"
          style={{
            fill: '#2f1c63',
          }}
        />
        <circle
          className="st0"
          cx={185.1}
          cy={192}
          r={2.9}
          id="circle10"
          style={{
            fill: '#2f1c63',
          }}
        />
        <g id="g44">
          <path
            className="st0"
            d="m178.7 143.5 4.3 4.7s-17.1 16.1-17.3 16.2c-.2.1-3.8-4.3-3.8-4.3z"
            id="path12"
            style={{
              fill: '#2f1c63',
            }}
          />
          <path
            className="st0"
            id="polygon14"
            style={{
              fill: '#2f1c63',
            }}
            d="m190.9 142.8 23 22.4-4.7 4-24-20.6z"
          />
          <path
            transform="rotate(-180 185.617 137.75)"
            className="st0"
            id="rect16"
            style={{
              fill: '#2f1c63',
            }}
            d="M178.7 132.9h13.8v9.6h-13.8z"
          />
          <path
            transform="rotate(-45.001 166.51 152.036)"
            className="st0"
            id="rect18"
            style={{
              fill: '#2f1c63',
            }}
            d="M149 147.6h35.1v8.8H149z"
          />
          <circle
            className="st0"
            cx={178.7}
            cy={139.4}
            r={6.5}
            id="circle20"
            style={{
              fill: '#2f1c63',
            }}
          />
          <path
            transform="rotate(-134.999 190.342 152.034)"
            className="st0"
            id="rect22"
            style={{
              fill: '#2f1c63',
            }}
            d="M172.8 147.6h35.1v8.8h-35.1z"
          />
          <circle
            className="st0"
            cx={202.7}
            cy={164.4}
            r={6.5}
            id="circle24"
            style={{
              fill: '#2f1c63',
            }}
          />
          <path
            className="st0"
            id="rect26"
            style={{
              fill: '#2f1c63',
            }}
            d="M202.7 166.5h14.9v4.3h-14.9z"
          />
          <path
            className="st0"
            id="rect28"
            style={{
              fill: '#2f1c63',
            }}
            d="M201.7 157.9h15.9v4.3h-15.9z"
          />
          <g id="g42">
            <path
              transform="rotate(-45.001 180.294 152.036)"
              className="st1"
              id="rect30"
              style={{
                fill: '#9053ec',
              }}
              d="M162.7 147.6h35.1v8.8h-35.1z"
            />
            <circle
              className="st1"
              cx={192.5}
              cy={139.4}
              r={6.5}
              id="circle32"
              style={{
                fill: '#9053ec',
              }}
            />
            <path
              transform="rotate(-134.999 204.126 152.034)"
              className="st1"
              id="rect34"
              style={{
                fill: '#9053ec',
              }}
              d="M186.6 147.6h35.1v8.8h-35.1z"
            />
            <circle
              className="st1"
              cx={216.5}
              cy={164.4}
              r={6.5}
              id="circle36"
              style={{
                fill: '#9053ec',
              }}
            />
            <path
              className="st1"
              id="rect38"
              style={{
                fill: '#9053ec',
              }}
              d="M216.5 166.5h14.9v4.3h-14.9z"
            />
            <path
              className="st1"
              id="rect40"
              style={{
                fill: '#9053ec',
              }}
              d="M215.5 157.9h15.9v4.3h-15.9z"
            />
          </g>
        </g>
        <path
          className="st0"
          d="M150 149.9h-21.6c-6.9 0-12.6 5.6-12.6 12.6v15.3H150Z"
          id="path46"
          style={{
            fill: '#2f1c63',
          }}
        />
        <path
          className="st1"
          d="M176.6 149.9h-34.4c-6.9 0-12.6 5.6-12.6 12.6v15.3h47z"
          id="path48"
          style={{
            fill: '#9053ec',
          }}
        />
        <g id="g74">
          <g id="g68">
            <path
              className="st0"
              d="M153 146.6h-21.5c-3.7 0-6.8-3-6.8-6.8v-21.5c0-3.7 3-6.8 6.8-6.8H153c3.7 0 6.8 3 6.8 6.8v21.5c0 3.8-3 6.8-6.8 6.8z"
              id="path50"
              style={{
                fill: '#2f1c63',
              }}
            />
            <path
              className="st0"
              d="M124.8 135.3c-3.4 0-6.2-2.8-6.2-6.2 0-3.4 2.8-6.2 6.2-6.2z"
              id="path52"
              style={{
                fill: '#2f1c63',
              }}
            />
            <path
              className="st0"
              d="M159.8 135.3V123c3.4 0 6.2 2.8 6.2 6.2 0 3.3-2.8 6.1-6.2 6.1z"
              id="path54"
              style={{
                fill: '#2f1c63',
              }}
            />
            <path
              className="st1"
              d="M161.8 146.6h-21.5c-3.7 0-6.8-3-6.8-6.8v-21.5c0-3.7 3-6.8 6.8-6.8h21.5c3.7 0 6.8 3 6.8 6.8v21.5c0 3.8-3.1 6.8-6.8 6.8z"
              id="path56"
              style={{
                fill: '#9053ec',
              }}
            />
            <path
              className="st1"
              d="M133.5 135.3c-3.4 0-6.2-2.8-6.2-6.2 0-3.4 2.8-6.2 6.2-6.2z"
              id="path58"
              style={{
                fill: '#9053ec',
              }}
            />
            <path
              className="st1"
              d="M168.6 135.3V123c3.4 0 6.2 2.8 6.2 6.2-.1 3.3-2.8 6.1-6.2 6.1z"
              id="path60"
              style={{
                fill: '#9053ec',
              }}
            />
            <path
              className="st0"
              d="M151.1 142.2c6 0 10.9-4.9 10.9-10.9h-21.9c0 6 4.9 10.9 11 10.9z"
              id="path62"
              style={{
                fill: '#2f1c63',
              }}
            />
            <path
              className="st0"
              d="M146.5 126.8c.5-.6.8-1.4.8-2.3 0-2-1.6-3.6-3.6-3.6s-3.6 1.6-3.6 3.6c0 .9.3 1.6.8 2.3z"
              id="path64"
              style={{
                fill: '#2f1c63',
              }}
            />
            <path
              className="st0"
              d="M161.2 126.8c.5-.6.8-1.4.8-2.3 0-2-1.6-3.6-3.6-3.6s-3.6 1.6-3.6 3.6c0 .9.3 1.6.8 2.3z"
              id="path66"
              style={{
                fill: '#2f1c63',
              }}
            />
          </g>
          <circle
            className="st1"
            cx={147.3}
            cy={98}
            r={4.9}
            id="circle70"
            style={{
              fill: '#9053ec',
            }}
          />
          <path
            id="line72"
            style={{
              fill: 'none',
              stroke: '#9053ec',
              strokeWidth: 3.35879993,
              strokeLinecap: 'round',
              strokeMiterlimit: 10,
            }}
            d="M147.3 98.4v9"
          />
        </g>
        <circle
          className="st0"
          cx={137.9}
          cy={192}
          r={2.9}
          id="circle76"
          style={{
            fill: '#2f1c63',
          }}
        />
        <circle
          className="st0"
          cx={153.6}
          cy={192}
          r={2.9}
          id="circle78"
          style={{
            fill: '#2f1c63',
          }}
        />
        <circle
          className="st0"
          cx={169.4}
          cy={192}
          r={2.9}
          id="circle80"
          style={{
            fill: '#2f1c63',
          }}
        />
      </g>
    </SvgIcon>
  );
}
