import { Announcement } from '@mui/icons-material';
import { Avatar, Button, Card, CardActions, CardContent, CardHeader, Dialog, Typography } from '@mui/material';
import React from 'react';
import BootstrapDialogTitle from '../common/dialogs/BootstrapDialogTitle';
import Markdown from '../markdown/markdown';

export default function NewsItem(props: { newsItem: { head; content } }) {
  const [open, setOpen] = React.useState<boolean>(false);

  return (
    <Card
      variant="outlined"
      onClick={(e) => {
        if (!open) setOpen(true);
        e.stopPropagation();
      }}
      style={{ margin: '10px' }}
    >
      <CardHeader avatar={<Avatar><Announcement/></Avatar>} title={props.newsItem.head.title} subheader={props.newsItem.head.date}></CardHeader>
      <CardContent>
        <Typography>{props.newsItem.head.description}</Typography>
      </CardContent>
      <CardActions>
        <Button onClick={() => setOpen(true)} variant="contained" color="secondary">
          Read more
        </Button>
      </CardActions>

      <Dialog open={open} maxWidth="xl">
        <BootstrapDialogTitle id="news" onClose={() => setOpen(false)}>
          <CardHeader avatar={<Avatar><Announcement/></Avatar>} title={props.newsItem.head.title} subheader={props.newsItem.head.date}></CardHeader>
        </BootstrapDialogTitle>
        <CardContent>
          <Markdown children={props.newsItem.content}></Markdown>
        </CardContent>
      </Dialog>
    </Card>
  );
}
