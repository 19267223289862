import { Box, Card, CardContent, CardHeader } from '@mui/material';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import SEO from '../common/seo';
import NewsItem from './NewsItem';

export const newsQuery = graphql`
  query NewsQuery {
    allMarkdownRemark(
      filter: { frontmatter: { tags: { in: ["news"] }, edition: { eq: "published" } } }
      sort: { order: DESC, fields: frontmatter___sort }
    ) {
      nodes {
        frontmatter {
          date
          title
          description
          slug
          edition
          tags
        }
        rawMarkdownBody
      }
    }
  }
`;

export default function NewsCard(props: { maxItems: number }) {
  const news = useStaticQuery(newsQuery).allMarkdownRemark.nodes.map((node: any) => {
    return { head: node.frontmatter, content: node.rawMarkdownBody };
  });

  return (
    <Card style={{ overflowY: 'auto', marginBottom: '10px' }}>
      <CardHeader title={'NavAbility News'}></CardHeader>
      <CardContent>
        <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
          <SEO title="News" />
          {news.slice(0, props.maxItems).map((item) => (
            <NewsItem newsItem={item} />
          ))}
        </Box>
      </CardContent>
    </Card>
  );
}
