import { Box } from '@mui/material';
import React from 'react';
import SubscriptionUpdates from '../components/activity/subscription-updates';
import SEO from '../components/common/seo';
import HomeView from '../components/home/HomeView';
// import Paywall from '../paywall/paywall';

export default function HomePage({data, location}: any) {
  console.log(data);
  return (
    <Box style={{ height: '95vh', overflowY: 'auto' }}>
      <Box>
        <SEO title="NavAbility Home" />
        {/* <Paywall> */}
        <HomeView />
        <SubscriptionUpdates />
        {/* </Paywall> */}
      </Box>
    </Box>
  );
}
