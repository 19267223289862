import { useApolloClient } from '@apollo/client';
import { KeyboardArrowRight, Refresh } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useActions, { useAppDispatch } from '../../app/hooks';
import { UserState, selectUser } from '../../global/state/user-slice';
import { getIDToken } from '../../services/token-storage';
import CircularProgressWithLabel from '../common/CircularProgressWithLabel';
import { SolveSwarmIcon } from '../icons/icons';
import { RobotIcon } from '../icons/svg/RobotIcon';
import { HomeState, selectHome } from './HomeSlice';

function RobotSummaryCard() {
  const globalState = useApolloClient();
  const actions = useActions();
  const dispatch = useAppDispatch();

  const userState: UserState = useSelector(selectUser);
  const homeState: HomeState = useSelector(selectHome);

  // If the user updated, get the robots.
  useEffect(() => {
    getRobots();
  }, [getIDToken(), userState]);

  function getRobots() {
    if (userState.user) {
      actions.getRobotsLatest({ apolloClient: globalState, user: userState.user });
    }
  }

  return (
    <Box>
      <Card style={{ padding: '10px' }}>
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <CardHeader
            title={`Top 5 recently active robots ${
              homeState.status == 'idle' ? `(Total: ${homeState.robotsLatest?.length})` : ''
            }`}
          ></CardHeader>
          <Button
            disabled={homeState.status == 'loading'}
            startIcon={<Refresh />}
            variant="outlined"
            onClick={() => getRobots()}
            style={{ height: '50px' }}
          >
            Refresh
          </Button>
        </Box>
        <CardContent>
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center">
                    <Tooltip title="Robot">
                      <Typography style={{ fontWeight: 600 }}>Robot</Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title="Last active">
                      <Typography>Last active</Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title="Last active">
                      <Typography>Latest session</Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title="Navigate">
                      <Typography>Actions</Typography>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {homeState.status == 'loading' ? (
                  <CircularProgressWithLabel
                    label="Loading..."
                    style={{ width: '96px', height: '96px', margin: '20px' }}
                  />
                ) : (
                  homeState.robotsLatest?.slice(0, 5).map((robot, i) => (
                    <TableRow key={i.toString()}>
                      <TableCell align="center">
                        <RobotIcon />
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip title={robot.label}>
                          <Typography style={{ fontWeight: 600 }}>{robot.label}</Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        <Typography>
                          {(robot.sessions?.length ?? 0) > 0
                            ? new Date(robot.sessions![0]?.lastUpdatedTimestamp)?.toLocaleString()
                            : 'Robot has no sessions yet'}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography>
                          {robot.sessions!.length > 0 ? <Typography>{robot.sessions![0].name}</Typography> : 'None'}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <IconButton edge="end" href={`/cloud/robots?searchRobot=${encodeURIComponent(robot.name)}`}>
                          <KeyboardArrowRight fontSize="large" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<SolveSwarmIcon fontSize="large" />}
            href="/cloud/robots"
          >
            Go to robots
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
}

export default RobotSummaryCard;
